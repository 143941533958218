<template>
  <div>
    <div v-if="data.length > 0">
      <h3 class="mg-t-0">โครงการส่งเสริมธุรกิจ</h3>
      <el-row :gutter="20" type="flex" class="flex-warp">
        <el-col
          :sm="12"
          :md="8"
          :lg="6"
          :offset="0"
          v-for="(item, index) in data"
          :key="index"
          class="mg-b-3"
        >
          <Card
            :data="item"
            :interest="item.interest ? true : false"
            :type="'project'"
            @interest="interestProduct"
        /></el-col>
      </el-row>
    </div>
    <div v-else class="mg-t-3">
      <el-card
        ><h3 class="text-center">ไม่พบข้อมูลโครงการส่งเสริมธุรกิจ</h3></el-card
      >
    </div>
  </div>
</template>
<script>
import Card from "@/components/product/ProductCard";
export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  components: {
    Card,
  },
  data() {
    return {};
  },
  methods: {
    interestProduct(id = 0, type = "link") {
      this.$emit("fetchResult");
    },
  },
};
</script>
