<template>
  <div>
    <div v-if="size == 'S' && sizeS.length > 0">
      <h3 class="mg-t-0">สินเชื่อ</h3>
      <div>
        <div class="tab--credit">
          <el-radio-group v-model="activeName" style="margin-bottom: 30px">
            <el-radio-button
              label="short"
              v-if="filterSize(sizeS, 1).length > 0"
              >สินเชื่อหมุนเวียน</el-radio-button
            >
            <el-radio-button label="long" v-if="filterSize(sizeS, 2).length > 0"
              >สินเชื่อระยะกลาง-ยาว</el-radio-button
            >
          </el-radio-group>
        </div>
        <el-row
          v-if="activeName == 'short'"
          :gutter="20"
          type="flex"
          class="flex-warp"
        >
          <el-col
            :sm="12"
            :md="8"
            :lg="6"
            v-for="(item, index) in filterSize(sizeS, 1)"
            :key="index"
            class="mg-b-3"
          >
            <Card
              :interest="checkInterestS(item, 'S')"
              :type="'credit'"
              :data="item"
              @interest="interestProduct"
            />
          </el-col>
        </el-row>
        <el-row
          v-if="activeName == 'long'"
          :gutter="20"
          type="flex"
          class="flex-warp"
        >
          <el-col
            :sm="12"
            :md="8"
            :lg="6"
            v-for="item in filterSize(sizeS, 2)"
            :key="item._id"
            class="mg-b-3"
          >
            <Card
              :interest="checkInterestS(item, 'S')"
              :type="'credit'"
              :data="item"
              @interest="interestProduct"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="size == 'M' && sizeM.length > 0">
      <h3>สินเชื่อ</h3>
      <div>
        <div class="tab--credit">
          <el-radio-group v-model="activeName" style="margin-bottom: 30px">
            <el-radio-button
              label="short"
              v-if="filterSize(sizeM, 1).length > 0"
              >สินเชื่อหมุนเวียน</el-radio-button
            >
            <el-radio-button label="long" v-if="filterSize(sizeM, 2).length > 0"
              >สินเชื่อระยะกลาง-ยาว</el-radio-button
            >
          </el-radio-group>
        </div>
        <el-row
          v-if="activeName == 'short'"
          :gutter="20"
          type="flex"
          class="flex-warp"
        >
          <el-col
            :sm="12"
            :md="8"
            :lg="6"
            v-for="(item, index) in filterSize(sizeM, 1)"
            :key="index"
            class="mg-b-3"
          >
            <Card
              :interest="checkInterestS(item, 'M')"
              :type="'credit'"
              :data="item"
              @interest="interestProduct"
            />
          </el-col>
        </el-row>
        <el-row
          v-if="activeName == 'long'"
          :gutter="20"
          type="flex"
          class="flex-warp"
        >
          <el-col
            :sm="12"
            :md="8"
            :lg="6"
            v-for="item in filterSize(sizeM, 2)"
            :key="item._id"
            class="mg-b-3"
          >
            <Card
              :interest="checkInterestS(item, 'M')"
              :type="'credit'"
              :data="item"
              @interest="interestProduct"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="size == 'L' && sizeL.length > 0">
      <h3>สินเชื่อ</h3>
      <div>
        <div class="tab--credit">
          <el-radio-group v-model="activeName" style="margin-bottom: 30px">
            <el-radio-button
              label="short"
              v-if="filterSize(sizeL, 1).length > 0"
              >สินเชื่อหมุนเวียน</el-radio-button
            >
            <el-radio-button label="long" v-if="filterSize(sizeL, 2).length > 0"
              >สินเชื่อระยะกลาง-ยาว</el-radio-button
            >
          </el-radio-group>
        </div>
        <el-row
          v-if="activeName == 'short'"
          :gutter="20"
          type="flex"
          class="flex-warp"
        >
          <el-col
            :sm="12"
            :md="8"
            :lg="6"
            v-for="(item, index) in filterSize(sizeL, 1)"
            :key="index"
            class="mg-b-3"
          >
            <Card
              :interest="interestLwc"
              :type="'credit'"
              :data="item"
              @interest="interestProduct"
            />
          </el-col>
        </el-row>
        <el-row
          v-if="activeName == 'long'"
          :gutter="20"
          type="flex"
          class="flex-warp"
        >
          <el-col
            :sm="12"
            :md="8"
            :lg="6"
            v-for="item in filterSize(sizeL, 2)"
            :key="item._id"
            class="mg-b-3"
          >
            <Card
              :interest="interestLl"
              :type="'credit'"
              :data="item"
              @interest="interestProduct"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- <div
      v-if="
        (size == 'S' && sizeM.length == 0) ||
        (size == 'M' && sizeM.length == 0) ||
        (size == 'L' && sizeL.length == 0)
      "
      class="mg-t-3"
    >
      <el-card><h3 class="text-center">ไม่พบข้อมูลสินเชื่อ</h3></el-card>
    </div> -->
  </div>
</template>
<script>
import Card from "@/components/product/ProductCard";
export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
    size: {
      type: String,
      default: "",
    },
  },
  components: {
    Card,
  },
  watch: {
    data() {
      this.extractSize();
    },
  },
  data() {
    return {
      checked: 1,
      activeName: "short",
      sizeS: [],
      sizeM: [],
      sizeL: [],
      interestM: true,
      interestLwc: true,
      interestLl: true,
    };
  },
  mounted() {
    this.extractSize();
  },
  methods: {
    checkInterestS(data, size) {
      if (size == "S") {
        let s = this.sizeS.findIndex(
          (a) => a.interest == 1 && a.interestType == data.interestType
        );
        return s > -1 ? false : true;
      }
      if (size == "M") {
        let s = this.sizeM.findIndex(
          (a) => a.interest == 1 && a.interestType == data.interestType
        );
        return s > -1 ? false : true;
      }
    },
    interestProduct(id = 0) {
      this.$emit("fetchResult");
    },
    filterSize(data, type) {
      let filter = [];
      filter = data.filter((a) => a.interestType == type);
      return filter;
    },
    checkInterest() {
      let lwc = this.sizeL.findIndex(
        (a) => a.interest == 1 && a.interestType == 1
      );
      let lL = this.sizeL.findIndex(
        (a) => a.interest == 1 && a.interestType == 2
      );

      lwc > -1 ? (this.interestLwc = false) : null;
      lL > -1 ? (this.interestLl = false) : null;
    },
    extractSize() {
      let data = JSON.parse(JSON.stringify(this.data));
      this.sizeS = data.filter((a) => a.creditSizeS);
      this.sizeM = data.filter((a) => a.creditSizeM);
      this.sizeL = data.filter((a) => a.creditSizeL);
      let filter = [];
      if (this.size == "S") {
        filter = this.filterSize(this.sizeS, 1);
      }
      if (this.size == "M") {
        filter = this.filterSize(this.sizeM, 1);
      }
      if (this.size == "L") {
        filter = this.filterSize(this.sizeL, 1);
      }
      if (filter.length == 0) {
        this.activeName = "long";
      }
      this.checkInterest();
    },
  },
};
</script>
