<template>
  <div class="bordered rounded bg-white position-relative card-product h-100">
    <el-row :gutter="20" class="cr-pointer" @click.native="tokenToDetail">
      <el-col :span="24" :offset="0" style="min-height: 95.25px">
        <div class="card-normal">
          <img
            v-if="data.image"
            :src="data.image"
            class="img-content"
            @error="require('../../assets/exim-logo.jpg')"
            alt=""
          /><img
            v-else
            src="@/assets/exim-logo.jpg"
            alt="EXIM Bank"
            class="img-content"
            style="object-fit: contain"
          />
        </div>
      </el-col>
      <el-col :span="24" :offset="0">
        <div class="pd-5">
          <h4 class="mg-t-0 mg-b-7 text-ellipsis-2 font-20 mg-b-7 title">
            {{
              type == "credit"
                ? data.creditName
                : type == "project"
                ? data.projectName
                : type == "insurance"
                ? data.insuranceName
                : ""
            }}
          </h4>
          <p
            class="text-ellipsis-3 font-18 mg-y-7 text-grey description"
            v-html="
              type == 'credit'
                ? $options.filters.striphtml(data.attributeProductService)
                : type == 'project'
                ? $options.filters.striphtml(data.projectDescription)
                : type == 'insurance'
                ? $options.filters.striphtml(data.insuranceOverview)
                : ''
            "
          ></p>
          <div class="link mg-t-5">
            <a class="font-18" @click="tokenToDetail">
              สนใจและอ่านรายละเอียด
            </a>
          </div>
          <!-- <div
            class="interest-product hidden-md-and-up mg-t-3"
            :class="{ active: data.interest }"
            @click="
              interest && (type == 'insurance' || type == 'credit')
                ? (modalContact = true)
                : type == 'project'
                ? (modalLink = true)
                : alertText()
            "
          >
            <i class="fas fa-heart font-10"></i> สนใจ
          </div> -->
        </div>
      </el-col>
    </el-row>
    <div
      class="interest-product"
      :class="{ active: data.interest }"
      v-if="data.interest"
      @click="
        interest && (type == 'insurance' || type == 'credit')
          ? (modalContact = true)
          : !data.interest && type == 'project' && data.registerType == 1
          ? ((modalContact = true),
            (infoSuccess.projectName = data.projectName))
          : !data.interest && type == 'project' && data.registerType == 2
          ? ((modalLink = true), (infoSuccess.projectName = data.projectName))
          : alertText()
      "
    >
      <i class="fas fa-heart font-10"></i>
      {{ type == "project" ? "สมัครแล้ว" : "สนใจ" }}
    </div>
    <el-dialog
      :visible.sync="modalContact"
      :destroy-on-close="true"
      @close="resetfield()"
      width="45%"
    >
      <div v-if="type == 'project'">
        <h4 class="mg-t-0 mg-b-6">บุคคลที่ท่านต้องการสมัครเข้าร่วมโครงการ</h4>
        <h4 class="mg-t-0">
          {{ infoSuccess.projectName }}
        </h4>
      </div>
      <h4 class="mg-t-0" v-else>
        บุคคลที่ท่านต้องการให้ EXIM Bank
        ติดต่อกลับเพื่อนำเสนอรายละเอียดผลิตภัณฑ์เพิ่มเติม
      </h4>
      <el-form
        label-position="top"
        :rules="rules"
        :model="ruleForm"
        ref="ruleForm"
        class="text-left"
      >
        <el-form-item prop="projectType" v-if="type == 'project'">
          <el-checkbox-group v-model="ruleForm.projectType">
            <div>
              <el-checkbox label="self"
                >คุณ{{ user.name }} {{ user.surname }}</el-checkbox
              >
            </div>
            <div>
              <el-checkbox label="etc">ติดต่อกลับบุคคลอื่น</el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item prop="person" v-if="type != 'project'">
          <div>
            <el-radio v-model="ruleForm.person" label="self"
              >คุณ{{ user.name }} {{ user.surname }}</el-radio
            >
          </div>
          <div class="mg-t-7">
            <el-radio v-model="ruleForm.person" label="etc"
              >ติดต่อกลับบุคคลอื่น
            </el-radio>
          </div>
        </el-form-item>
        <div
          v-if="
            (type != 'project' && ruleForm.person == 'etc') ||
            (type == 'project' && checkType)
          "
          class="text-left"
        >
          <el-row :gutter="20">
            <el-col :sm="12" :offset="0">
              <el-form-item label="ชื่อ" prop="name">
                <el-input v-model="ruleForm.name" placeholder="ชื่อ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :offset="0">
              <el-form-item label="นามสกุล" prop="surname">
                <el-input
                  v-model="ruleForm.surname"
                  placeholder="นามสกุล"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :offset="0">
              <el-form-item label="เบอร์โทรศัพท์" prop="mobilephone">
                <el-input
                  v-mask="'##########'"
                  v-model="ruleForm.mobilephone"
                  placeholder="เบอร์โทรศัพท์"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :offset="0">
              <el-form-item label="อีเมล" prop="email">
                <el-input
                  type="email"
                  v-model="ruleForm.email"
                  placeholder="อีเมล"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="type == 'credit'">
          <el-divider></el-divider>
        </div>
        <div v-if="type == 'credit'">
          <el-row>
            <el-col :lg="16">
              <el-form-item
                label="สาขาที่ท่านสนใจให้เจ้าหน้าที่ติดต่อกลับ"
                prop="branch"
              >
                <el-select
                  class="w-100"
                  v-model="ruleForm.branch"
                  placeholder="เลือกสาขาที่ท่านสนใจให้เจ้าหน้าที่ติดต่อกลับ"
                >
                  <el-option
                    v-for="(data, index) in branchOption"
                    :key="index"
                    :label="data.label"
                    :value="data.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="text-right mg-t-1">
          <div class="is-flex js-end ai-center mg-t-1">
            <a
              @click="modalContact = false"
              class="text-grey-light font-18 mg-r-1"
              >ยกเลิก</a
            >
            <div>
              <el-button
                id="saveData"
                type="primary"
                :loading="btnLoading"
                class="wid180"
                @click="sendData()"
                >ส่งข้อมูล</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="modalSuccess"
      :destroy-on-close="true"
      width="40%"
      class="text-center"
    >
      <svg
        class="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path
          class="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
      <div v-if="type == 'credit' || type == 'insurance'">
        <h2 class="text-success mg-y-5">
          EXIM Bank ได้รับข้อมูลของท่านแล้ว! และจะติดต่อกลับ
        </h2>
        <h4 class="mg-t-5 mg-b-1 pd-b-1">
          ขอขอบคุณที่ให้ความสนใจผลิตภัณฑ์ของธนาคาร
        </h4>
        <!-- <h4 class="mg-y-5 text-dark">
        คุณ{{ infoSuccess.name }} {{ infoSuccess.surname }}
      </h4> -->
      </div>
      <div v-if="type == 'project'">
        <h2 class="text-success mg-y-5">ท่านได้สมัครเข้าร่วมโครงการ</h2>
        <h4 class="mg-y-5">{{ infoSuccess.projectName }}</h4>
        <h4 class="text-success mg-y-5">เรียบร้อยแล้ว!</h4>
        <h4 class="mg-y-1 font-20">
          ขอขอบคุณที่ให้ความสนใจโครงการของ EXIM Bank
        </h4>
      </div>

      <div class="text-center mg-t-1">
        <a @click="modalSuccess = false" class="text-primary"
          >กลับหน้าแบบประเมิน</a
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="modalLink"
      v-if="modalLink"
      :destroy-on-close="true"
      width="40%"
    >
      <h5 class="text-dark mg-b-0 mg-t-0 font-22">
        ท่านสามารถสมัครเข้าร่วมโครงการ
      </h5>
      <h5 class="text-dark mg-t-7 font-22">
        {{ infoSuccess.projectName }} ได้ที่
      </h5>
      <a :href="data.registerUrl" target="_blank" class="link-interest d-flex"
        ><div class="is-flex ai-center">
          <img src="@/assets/icon-link.png" class="mg-r-6" alt="" />{{
            data.registerUrl
          }}
        </div>
      </a>
      <div class="text-right mg-t-1">
        <div class="is-flex js-end ai-center mg-t-1">
          <a @click="modalLink = false" class="text-grey-light font-18 mg-r-1"
            >ยกเลิก</a
          >
          <div>
            <el-button
              id="saveData"
              type="primary"
              :loading="btnLoading"
              @click="linkInterest()"
              >สนใจ</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import jwt from "jsonwebtoken";
export default {
  data() {
    return {
      modalSuccess: false,
      modalContact: false,
      btnLoading: false,
      ruleForm: {
        projectType: [],
        person: "",
        branch: "",
        juristicId: null,
        productId: null,
        type: null,
        name: "",
        surname: "",
        email: "",
        mobilephone: "",
        companyId: null
      },
      rules: {
        projectType: [
          {
            required: true,
            message: "กรุณาเลือกบุุคคลติดต่อกลับ",
            trigger: "change"
          }
        ],
        person: [
          {
            required: true,
            message: "กรุณาเลือกบุุคคลติดต่อกลับ",
            trigger: "change"
          }
        ],
        name: [
          {
            required: true,
            message: "กรุณากรอกชื่อ",
            trigger: "change"
          }
        ],
        surname: [
          {
            required: true,
            message: "กรุณากรอกนามสกุล",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur"
          },
          {
            message: "รูปแบบอีเมลไม่ถูกต้อง",
            trigger: "blur",
            type: "email"
          }
        ],
        mobilephone: [
          {
            required: true,
            message: "กรุณากรอกเบอร์โทรศัพท์มือถือ",
            trigger: "blur"
          },
          {
            min: 10,
            max: 13,
            message: "กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบ",
            trigger: "blur"
          }
        ],
        branch: [
          {
            required: true,
            message: "กรุณาเลือกสาขาที่ท่านสนใจให้เจ้าหน้าที่ติดต่อกลับ",
            trigger: "change"
          }
        ]
      },
      infoSuccess: {
        name: "",
        surname: "",
        projectName: ""
      },
      modalLink: false
    };
  },
  props: {
    interest: {
      type: Boolean,
      default: true
    },
    data: {
      type: null,
      default: []
    },
    type: {
      type: String,
      default: ""
    }
  },
  computed: {
    checkType() {
      let index = this.ruleForm.projectType.findIndex(a => a == "etc");
      return index > -1 ? true : false;
    },
    branchOption() {
      return this.$store.state.branch;
    }
  },
  methods: {
    tokenToDetail() {
      let obj = {
        productId: this.data._id,
        type: this.type,
        userId: this.user.id,
        allow:
          this.interest && (this.type == "insurance" || this.type == "credit")
            ? false
            : !this.data.interest &&
              this.type == "project" &&
              this.data.registerType == 1
            ? false
            : !this.data.interest &&
              this.type == "project" &&
              this.data.registerType == 2
            ? false
            : true
      };
      let token = jwt.sign(obj, "ABCDEFG123456789");
      this.$router.push(`/product-detail/${this.type}/${token}`);
    },
    alertText() {
      let text = "ไม่สามารถกดสนใจประกันการส่งออกได้มากกว่า 1 ประกัน";
      if (this.data.type == "credit") {
        text = "ไม่สามารถกดสนใจสินเชื่อประเภทเดียวกันได้มากกว่า 1 สินเชื่อ";
      }
      if (!this.data.interest) {
        this.$message.error(text);
      }
    },
    interestProduct() {
      this.modalLink = false;
    },
    linkInterest() {
      this.btnLoading = true;
      if (!this.interest) {
        let obj = {
          juristicId: this.user.corporateNumberInterest,
          productId: this.data._id,
          type: this.type,
          nameDefault: this.user.name,
          surnameDefault: this.user.surname,
          mobileDefault: this.user.mobilephone,
          emailDefault: this.user.email,
          name: "",
          surname: "",
          mobilephone: "",
          email: "",
          branch: this.ruleForm.branch,
          companyId: this.user.companyId
        };
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        HTTP.defaults.baseURL = this.url;
        HTTP.post("/interest/product", obj)
          .then(res => {
            if (res.data.success) {
              this.modalSuccess = true;
              this.modalLink = false;
              this.infoSuccess.name = obj.name;
              this.infoSuccess.surname = obj.surname;
              this.resetfield();
              this.$emit("interest");
            } else {
              this.alertCatchError(res.data.data);
            }
            this.btnLoading = false;
          })
          .catch(e => {
            this.btnLoading = false;
            if (e == "Error: Request failed with status code 401") {
              this.checkAuth();
            } else {
              if (e != "Error: timeout of 120000ms exceeded") {
                this.alertCatchError(e);
              }
            }
          });
      }
      this.btnLoading = false;
    },
    resetfield() {
      this.ruleForm = {
        projectType: [],
        person: "",
        branch: "",
        juristicId: null,
        productId: null,
        type: null,
        name: "",
        surname: "",
        email: "",
        mobilephone: "",
        companyId: null
      };
    },
    sendData() {
      let obj;
      this.btnLoading = true;
      if (this.type == "project") {
        obj = {
          juristicId: this.user.corporateNumberInterest,
          productId: this.data._id,
          type: this.type,
          nameDefault: this.user.name,
          surnameDefault: this.user.surname,
          mobileDefault: this.user.mobilephone,
          emailDefault: this.user.email,
          name: this.ruleForm.name,
          surname: this.ruleForm.surname,
          email: this.ruleForm.email,
          mobilephone: this.ruleForm.mobilephone,
          branch: this.ruleForm.branch,
          companyId: this.user.companyId
        };
      } else if (
        this.ruleForm.person == "self" ||
        this.ruleForm.person == null
      ) {
        obj = {
          juristicId: this.user.corporateNumberInterest,
          productId: this.data._id,
          type: this.type,
          nameDefault: this.user.name,
          surnameDefault: this.user.surname,
          mobileDefault: this.user.mobilephone,
          emailDefault: this.user.email,
          name: "",
          surname: "",
          email: "",
          mobilephone: "",
          branch: this.ruleForm.branch,
          companyId: this.user.companyId
        };
      } else {
        obj = {
          juristicId: this.user.corporateNumberInterest,
          productId: this.data._id,
          type: this.type,
          nameDefault: "",
          surnameDefault: "",
          mobileDefault: "",
          emailDefault: "",
          name: this.ruleForm.name,
          surname: this.ruleForm.surname,
          mobilephone: this.ruleForm.mobilephone,
          branch: this.ruleForm.branch,
          email: this.ruleForm.email,
          companyId: this.user.companyId
        };
      }

      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          HTTP.defaults.baseURL = this.url;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post("interest/product", obj)
            .then(res => {
              if (res.data.success) {
                this.modalContact = false;
                this.modalSuccess = true;
                this.infoSuccess.name = obj.name;
                this.infoSuccess.surname = obj.surname;
                this.$emit("interest");
                this.resetfield();
              } else {
                this.alertCatchError(res.data.data);
              }
              this.btnLoading = false;
            })
            .catch(e => {
              this.btnLoading = false;
              if (e == "Error: Request failed with status code 401") {
                this.checkAuth();
              } else {
                if (e != "Error: timeout of 120000ms exceeded") {
                  this.alertCatchError(e);
                }
              }
            });
        } else {
          this.btnLoading = false;
        }
      });
    }
  }
};
</script>
