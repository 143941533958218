<template>
  <div>
    <div v-if="data.length > 0">
      <h3 class="mg-t-0">ผลิตภัณฑ์และบริการนำเสนอทั้งหมด</h3>
      <el-row :gutter="20" type="flex" class="flex-warp">
        <el-col
          :sm="12"
          :md="8"
          :lg="6"
          :offset="0"
          v-for="(item, index) in data"
          :key="index"
          class="mg-b-3"
        >
          <Card
            :data="item"
            :type="item.type"
            :interest="checkData(item)"
            @interest="interestProduct"
        /></el-col>
      </el-row>
    </div>
    <div v-else class="mg-t-3">
      <el-card
        ><h3 class="text-center">ไม่พบข้อมูลผลิตภัณฑ์และบริการ</h3></el-card
      >
    </div>
  </div>
</template>
<script>
import Card from "@/components/product/ProductCard";
export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  components: {
    Card,
  },
  methods: {
    checkData(data) {
      let array = JSON.parse(JSON.stringify(this.data));
      if (data.type == "credit") {
        let sizeM = array.filter(
          (a) => (a.creditSizeM || a.creditSizeS) && a.type == "credit"
        );
        let sizeL = array.filter((a) => a.creditSizeL && a.type == "credit");
        if (data.creditSizeM || data.creditSizeS) {
          let m = sizeM.findIndex(
            (a) => a.interest == 1 && a.interestType == data.interestType
          );
          return m > -1 ? false : true;
        }
        if (data.creditSizeL) {
          let wc = sizeL.findIndex(
            (a) => a.interest == 1 && a.interestType == 3
          );
          let l = sizeL.findIndex(
            (a) => a.interest == 1 && a.interestType == 4
          );
          if (data.interestType == 3) {
            return wc > -1 ? false : true;
          }
          if (data.interestType == 4) {
            return l > -1 ? false : true;
          }
        }
        return true;
      } else if (data.type == "insurance") {
        let inter = this.data.findIndex(
          (a) => a.interest == 1 && a.type == "insurance"
        );
        return inter > -1 ? false : true;
      } else {
        return false;
      }
    },
    interestProduct(id = 0) {
      this.$emit("fetchResult");
    },
  },
};
</script>
