<template>
  <div>
    <div v-if="data.length > 0">
      <h3 class="mg-t-0">ประกันการส่งออก</h3>
      <el-row :gutter="20" v-if="data.length > 0" type="flex" class="flex-warp">
        <el-col
          :sm="12"
          :md="8"
          :lg="6"
          v-for="item in data"
          :key="item._id"
          class="mg-b-3"
        >
          <Card
            :type="'insurance'"
            :interest="interest"
            :data="item"
            @interest="interestProduct"
        /></el-col>
      </el-row>
    </div>
    <div v-else class="mg-t-3">
      <el-card><h3 class="text-center">ไม่พบข้อมูลประกันการส่งออก</h3></el-card>
    </div>
  </div>
</template>
<script>
import Card from "@/components/product/ProductCard";
export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  components: {
    Card,
  },
  watch: {
    data() {
      this.checkInterest();
    },
  },
  mounted() {
    this.checkInterest();
  },
  data() {
    return {
      interest: true,
    };
  },
  methods: {
    checkInterest() {
      let inter = this.data.findIndex((a) => a.interest == 1);
      inter > -1 ? (this.interest = false) : null;
    },
    interestProduct(id = 0) {
      this.$emit("fetchResult");
    },
  },
};
</script>
